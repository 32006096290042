export const urlEscape = url => {
  url = unescape(url);
  const onlineImagePattern = /^((http|https):\/\/)/;
  return !onlineImagePattern.test(url) ? escape(url) : url;
};

export function stripHtmlTagsAndFormatting(text?: string | null): string {
  return stripFormatting(stripHtmlTags(text));
}

export function stripFormatting(text?: string | null): string {
  if (!text) {
    return '';
  }

  return text.trim().replace(/[\n\t\r\s]+/gm, ' ');
}

export function stripHtmlTags(html?: string | null): string {
  if (!html) {
    return '';
  }
  return html.replace(/(<[A-Za-z!/?][^><]*>)+/gm, ' ').trim();
}

export const ignoreDuplicate = items =>
  items.filter(
    ({ id }, index) => !items.map(x => x.id).includes(id, index + 1),
  );